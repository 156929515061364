<template>
    <div>
        <!--        <BlurHashImage-->
        <!--            v-if="blurHash"-->
        <!--            :hash="blurHash"-->
        <!--            classes="w-full h-full object-contain object-center"-->
        <!--            :class="isLoaded ? 'hidden' : 'block'"-->
        <!--        />-->
        <!--        <picture :class="!blurHash || isLoaded ? 'block' : 'hidden'">-->
        <picture>
            <!--            <NuxtPictureSource-->
            <!--                v-if="types.includes(WebImageType.AVIF)"-->
            <!--                :src="-->
            <!--                    inventoryImageUrl(props.src, {-->
            <!--                        ...settings,-->
            <!--                        ...{-->
            <!--                            width: width ?? undefined,-->
            <!--                            maxWidth: maxWidth ?? undefined,-->
            <!--                            height: height,-->
            <!--                            format: WebImageType.AVIF,-->
            <!--                        },-->
            <!--                    })-->
            <!--                "-->
            <!--                :width="width"-->
            <!--                :sizes="sizes"-->
            <!--                :quality="quality"-->
            <!--                :ratio="ratio"-->
            <!--                format="avif"-->
            <!--            />-->
            <NuxtPictureSource
                v-if="types.includes(WebImageType.WEBP)"
                :src="
                    inventoryImageUrl(props.src, {
                        ...settings,
                        ...{
                            width: width ?? undefined,
                            maxWidth: maxWidth ?? undefined,
                            height: height,
                            format: WebImageType.WEBP,
                        },
                    })
                "
                :width="width"
                :sizes="sizes"
                :quality="quality"
                :ratio="ratio"
                format="webp"
            />
            <NuxtPictureSource
                :src="
                    inventoryImageUrl(props.src, {
                        ...settings,
                        ...{
                            width: width ?? undefined,
                            maxWidth: maxWidth ?? undefined,
                            height: height,
                        },
                    })
                "
                :width="width"
                :sizes="sizes"
                :quality="quality"
                :ratio="ratio"
                format="jpeg"
            />
            <img
                :src="
                    inventoryImageUrl(props.src, {
                        ...settings,
                        ...{
                            width: width ?? undefined,
                            maxWidth: maxWidth ?? undefined,
                            height: height,
                        },
                    })
                "
                :alt="alt"
                :class="classes"
                :loading="loading"
                :width="width ? `${width}px` : undefined"
                @load="isLoaded = true"
            />
        </picture>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { inventoryImageUrl } from '~/utils/functions'
import type { ImageParamOptions } from '~/utils/types/inventory'
import {
    type VehicleImageRatio,
    WebImageType,
} from '~/utils/types/inventory/enums'
// import BlurHashImage from '~/components/Vehicle/BlurHashImage.vue'

const props = defineProps({
    src: {
        type: String,
        required: true,
    },
    width: {
        type: Number,
    },
    maxWidth: {
        type: Number,
    },
    ratio: {
        type: Number as PropType<VehicleImageRatio | null>,
        default: null,
    },
    sizes: {
        type: String,
        required: true,
    },
    loading: {
        type: String as PropType<'eager' | 'lazy'>,
        default: 'eager',
    },
    types: {
        type: Array as PropType<WebImageType[]>,
        default: () => ['webp', 'avif'],
    },
    settings: {
        type: Object as PropType<ImageParamOptions>,
        default: () => {},
    },
    alt: {
        type: String,
    },
    classes: {
        type: String,
    },
    quality: {
        type: Number,
        default: 80,
    },
    blurHash: {
        type: String,
    },
})

const isLoaded = ref(false)

const height = computed<number | undefined>(() => {
    const width = props.width ?? 0
    const ratio = props.ratio ?? 0

    if (width === 0 || ratio === 0) {
        return undefined
    }

    return Math.round(width * ratio)
})
</script>
