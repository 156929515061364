<template>
    <source
        :media="media"
        :srcset="srcset"
        :sizes="cSizes.sizes"
        :type="type"
    />
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { VehicleImageRatio } from '~/utils/types/inventory/enums'

const props = defineProps({
    src: {
        type: String,
        required: true,
    },
    sizes: {
        type: String,
        required: true,
    },
    width: {
        type: Number,
    },
    format: {
        type: String,
        required: true,
    },
    media: {
        type: String,
    },
    quality: {
        type: Number,
        default: 80,
    },
    ratio: {
        type: Number as PropType<VehicleImageRatio | null>,
        default: null,
    },
})

const img = useImage()

const cSizes = computed(() =>
    img.getSizes(props.src, {
        sizes: props.sizes,
        modifiers: {
            format: props.format ?? undefined,
            quality: props.quality,
        },
    }),
)

const srcset = computed(() => {
    if (!cSizes.value || props.width === undefined) {
        return ''
    }

    const width = props.width ?? 0

    return cSizes.value.srcset
        .split(', ')
        .map((value: string) => {
            const valueSplit = value.split(' ')

            if (valueSplit.length !== 2) {
                return value
            }

            let parseWidth = parseInt(valueSplit[1].replace('w', ''))

            if (parseWidth > width) {
                parseWidth = width
            }

            if (props.ratio && value.includes('h_')) {
                value = replaceHeightWithRatioHeight(
                    value,
                    props.ratio,
                    parseWidth,
                )
            }

            return value.replace(`w_${width}`, `w_${parseWidth}`)
        })
        .join(', ')
})

const type = computed(() => `image/${props.format}`)

function replaceHeightWithRatioHeight(
    value: string,
    ratio: number,
    width: number,
) {
    const paramsArr = value.split(',')
    let fullHeightValue = ''

    for (let i = 0; i < paramsArr.length; i++) {
        const param = paramsArr[i]

        if (param.includes('h_')) {
            fullHeightValue = param
        }
    }

    const ratioHeight = Math.round(width * ratio)
    return value.replace(fullHeightValue, `h_${ratioHeight}`)
}
</script>
